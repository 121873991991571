




















































































































// Vue
import Vue from "vue";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// Services
import AuthService from "@/core/services/auth.service";
// Models
import { AuthUser } from "@/core/_models/authUser.model";
import { _Error } from "@/core/_models/_error.model";

export default Vue.extend({
  name: "Login",
  mixins: [validationMixin],

  data() {
    return {
      appVersionNumber: process.env.VUE_APP_VERSION_NUMBER,
      isLoading: false,
      isValid: false,

      password: "",
      email: "",

      errorLogin: false,
      errorMessage: undefined as string | undefined,
      successLogin: false
    };
  },

  validations: {
    email: { required },
    password: { required }
  },

  methods: {
    /**
     * Logs in a User using email and password as credentials.
     *
     * @author Nick Brahmir
     */
    login(): void {
      this.isLoading = true;
      this.errorLogin = false;

      AuthService.login(this.email, this.password)
        .then((data: AuthUser) => {
          // Check errors.
          if (!data) {
            this.errorLogin = true;
            this.errorMessage = "There was an unexpected error trying to sign in. Please try again.";
            return;
          }

          // If no errors, update State and reroute to Dashboard page.
          const user: AuthUser = data;
          this.$store.dispatch("LOGIN", user);

          // Set the currentUser's Main Theme Color.
          this.$vuetify.theme.themes.light.primary = AuthService.getMainThemeColor();
        })
        .catch((error: _Error) => {
          this.errorLogin = true;

          if (!error.message) {
            this.errorMessage = "There was an unexpected error trying to sign in. Please try again.";
            return;
          }

          this.errorMessage = error.message;
        })
        .finally(() => {
          this.password = "";
          this.isLoading = false;
        });
    },

    /**
     * Clears the login form.
     *
     * @author Nick Brahimir
     */
    clearForm: function (): void {
      this.$v.$reset();
      this.password = "";
      this.email = "";
    }
  },

  computed: {
    /**
     * Parses any password errors.
     *
     * @author Nick Brahimir
     */
    passwordErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.password.required && errors.push("Please enter your password");
      return errors;
    },

    /**
     * Parses any email errors.
     *
     * @author Nick Brahimir
     */
    emailErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Please enter your email");
      return errors;
    }
  }
});
